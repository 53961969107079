<template>
  <div v-if="vReady" class="flex" style="margin-top:70px;flex-direction: column;height:calc(100vh - 70px)">
    <tab-page :menu="menu" :full="false" @selected="onSelect"></tab-page>
    <div class="flex" v-if="isDetail">
      <div class="unselect flex-align" style="padding:24px 0 24px 54px" @click="$router.back()"><img src="/static/icon/u_arrow-left.svg"> <div class="body2 main">목록으로 돌아가기</div></div>
    </div>
    <keep-alive :include="['Market']">
      <router-view id="router" style="flex:1;overflow-y: auto"/>
    </keep-alive>
  </div>
</template>

<script>
  import TabPage from "../module/TabPage";
  import ServiceAdminAPIMixin from "../../mixins/ServiceAdminAPIMixin";
  export default {
    name: "AppMarket",
    mixins: [
      ServiceAdminAPIMixin
    ],
    components: {TabPage},
    created() {
      if(this.$route.query.i) {
        this.$store.commit('setAdmin', {
          id: Number(this.$route.query.i),
          token: this.$route.query.tn,
          parent: this.$route.query.parent
        });
        this.setAuth();

        this.request.serviceAdmin.get(`service/${this.$route.query.s}/user`).then(res => {
          console.log(res.data.user)
          this.$store.commit('setUser', res.data.user);
        });

        this.request.serviceAdmin.get(`launchpack/service/${this.$route.query.s}`).then(res=>{
          this.$store.commit('setUserService', res.data);
          if(this.$route.query.detail) {
            this.vReady = true;
            this.$router.replace(`/app_market/detail?id=${this.$route.query.detail}`);
          }
          else if(this.$route.query.order_list) {
            this.vReady = true;
            this.$router.replace('/app_market/order_list');
          }

          else if(this.$route.query.category) {
            this.vReady = true;
            this.$router.replace(`/app_market/list?tab=${this.$route.query.category}`);
          }
          else if(this.$route.query.pack) {
            this.$axios.get('https://master-dev.launchpack.co.kr:1111/user/0/mapping/product?&category=47&fields=id,name,params&page_num=1&page_length=100').then(res=>{
              let id = res.data.data.filter(i=> {
                return this.$route.query.pack === i.params.filter(p=>{ return p.name === 'key'})[0].value
              })[0].id;
              this.vReady = true;
              this.$router.push(`/app_market/detail?id=${id}`);
            });
          }
          else if(this.$route.query.plugin) {
            this.$axios.get('https://master-dev.launchpack.co.kr:1111/user/0/mapping/product?&category=48&fields=id,name,params&page_num=1&page_length=100').then(res=>{
              let id = res.data.data.filter(i=> {
                return this.$route.query.plugin === i.params.filter(p=>{ return p.name === 'key'})[0].value
              })[0].id;
              this.vReady = true;
              this.$router.push(`/app_market/detail?id=${id}`);
            });
          }
          else {
            this.vReady = true;
            if(this.$route.path === '/app_market') {
              this.onSelect(this.menu[0]);
            }
          }
        });
      } else {
        this.vReady = true;
      }
    },
    beforeDestroy() {
      //document.getElementById('ch-plugin').style.display = '';
    },
    mounted() {
      this.$nextTick(()=>{
        // setTimeout(()=>{
        //   document.getElementById('ch-plugin').style.display = 'none';
        // },1000);


        // 닫기 이벤트
        window.onbeforeunload = () => {
          window.parent.opener.postMessage({close: true, adminRedirect: false}, '*');
        };
      });
    },
    data() {
      return {
        vReady: false,
        isDetail: false,
        menu: [
          {
            id: 0,
            name: '앱 마켓',
            path: 'list'
          },
          {
            id: 1,
            name: '구매내역',
            path: 'order_list'
          }
        ]
      }
    },
    watch: {
      $route(n) {
        this.isDetail = n.path.indexOf('detail')>-1 || n.path.indexOf('inquiry_reg')>-1;
      }
    },
    methods: {
      onSelect(e) {
        this.routerPush('/app_market/'+e.path);
      }
    }
  }
</script>

<style lang="stylus" scoped>

</style>
